$(function(){
    $(".l-drawerClose").on("click",function(){
        $("body").toggleClass("have_curtain");
        $(".l-layout").toggleClass("is_active");
        $(".l-drawer").fadeToggle(100);
        $(this).toggleClass("is_active");
        // $(".l-drawer").toggleClass("is_active")
    });
    // $(".l-drawerClose").on("click",function(){
    //     console.log('drawerbtn');
    //     $(".l-layout").toggleClass("is_active");
    //     $(".l-drawer").fadeOut();
    //     // $(".l-drawer").toggleClass("is_active")
    //     $(".p-header__burger").toggleClass("is_active");
    //     $("body").toggleClass("have_curtain");
    // });
})

// スムーススクロール
$(function(){
    $('a[href^="#"]').click(function(){
      var speed = 500;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top;
      $("html, body").animate({scrollTop:position}, speed, "swing");
      return false;
    });
  });

// $(function(){
//     $(".ec-drawerRoleClose").on("click",function(){
//         $("body").removeClass("have_curtain")
//         $(".ec-layoutRole").removeClass("is_active")
//         $(".ec-drawerRole").removeClass("is_active")
//         $(".ec-drawerRoleClose").removeClass("is_active")
//     })
// })